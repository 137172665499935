<template>
    <client-page>
        <sub-visual
        sh="개인정보처리방침"
        sc="(주) 에프원시큐리티 개인정보 처리방침"
        tabActive="Contact"
        icon="/images/sub/visual/sv1-icon.svg"
        bg="/images/sub/visual/sv1.png">
        </sub-visual>

        <section class="sub-section sub-section--last">
            <v-container>
                <p class="font-size-14 font-size-lg-18">
                    (주) 에프원시큐리티 개인정보 처리방침 <br><br><br>



                    (주)에프원시큐리티(이하 ‘회사’라 한다)는 개인정보 보호법 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리지침을 수립·공개합니다.<br>
                    제1조(개인정보의 처리목적)<br>
                    회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.<br>
                    1. 업무제휴 문의<br>
                    당사 홈페이지(담당자 메일)를 통한 업무제휴 및 문의사항의 메일 수신을 위해 개인정보를 처리합니다.<br>
                    2. 채용 인사 업무<br>
                    채용 인사 업무를 위해 지원자 또는 재직자의 개인정보를 처리합니다.<br><br>


                    제2조(개인정보의 처리 및 보유기간)<br>
                    ① 회사는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집시에 동의받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.<br>
                    ② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.<br>
                    1. 홈페이지 통한 업무제휴 및 문의사항 접수 : 해당 업무 종료시까지<br>
                    2. 채용 인사 업무<br>
                    1) 지원자 개인정보(이력서) : 채용 업무 종료시 즉시 파쇄<br>
                    2) 재직자 : 퇴사후 1년까지<br><br>


                    제3조(처리하는 개인정보의 항목)<br>
                    1. 업무 제휴 및 문의사항<br>
                    필수항목 : 이름, 이메일주소, 연락처(휴대폰번호/회사 전화번호)<br>
                    선택항목 : -<br>
                    2. 채용 및 인사업무<br>
                    필수항목 : 이름, 생년월일, 휴대폰 번호, 이메일주소(E-Mail), 자택주소, 학력사항, 병역사항, 자격사항, 경력사항<br>
                    선택항목 : 결혼여부, 가족관계<br><br>


                    제4조(개인정보의 제3자 제공)<br>
                    회사는 원칙적으로 개인정보를 외부에 제공하지 않습니다. 다만, 개인정보보호법 제17조에 따른 다음의 경우에는 예외로 합니다.<br>
                    1. 관련 법령의 규정에 의하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우<br><br>


                    제5조(개인정보처리의 위탁)<br>
                    회사는 정보주체의 동의 없이 개인정보 처리위탁을 하고 있지 않습니다.<br>
                    제6조(정보주체의 권리·의무 및 행사방법)<br>
                    ① 정보주체는 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.<br>
                    1. 개인정보 열람요구<br>
                    2. 오류 등이 있을 경우 정정 요구<br>
                    3. 삭제요구<br>
                    4. 처리정지 요구<br>
                    ② 제1항에 따른 권리 행사는 회사에 대해 서면, 전화, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해 지체없이 조치하겠습니다.<br>
                    ③ 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.<br>
                    ④ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.<br>
                    ⑤ 정보주체는 개인정보 보호법 등 관계법령을 위반하여 회사가 처리하고 있는 정보주체 본인이나 타인의 개인정보 및 사생활을 침해하여서는 아니됩니다.<br><br>


                    제7조(개인정보의 파기)<br>
                    ① 회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.<br>
                    ② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.<br>
                    ③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.<br>
                    1. 파기절차<br>
                    회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.<br>
                    2. 파기방법<br>
                    회사는 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할 수 없도록 로우레밸포멧(Low Level Format) 등의 방법을 이용하여 파기하며, 종이 문서에 기록·저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.<br><br>


                    제8조(개인정보의 안전성 확보조치)<br>
                    회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.<br>
                    1. 관리적 조치 : 내부관리계획 수립·시행, 정기적 직원 교육 등<br>
                    2. 기술적 조치 : 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 고유식별정보 등의 암호화, 보안프로그램 설치<br>
                    3. 물리적 조치 : 전산실, 자료보관실 등의 접근통제<br><br>


                    제9조(개인정보 자동 수집 장치의 설치·운영 및 거부에 관한 사항)<br>
                    회사는 정보주체의 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용하지 않습니다.<br><br>

                    제10조(개인정보 보호책임자)<br>
                    ①	 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.<br>
                    ▶ 개인정보 보호책임자<br>
                        소속 : 정보보호컨설팅팀<br>
                        직책 : 김기환 상무<br>
                        연락처 : itconsult@f1security.co.kr <br>
                        ※ 개인정보 보호 담당부서로 연결됩니다.<br>
                    ▶ 개인정보 보호 담당부서<br>
                        부서명 : 영업본부<br>
                        담당자 : 조장원 이사<br>
                        연락처 : ken25@f1security.co.kr<br>
                    ② 정보주체께서는 회사의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 회사는 정보주체의 문의에 대해 지체없이 답변 및 처리해드릴 것입니다.<br><br>


                    제11조(개인정보 열람청구)<br>
                    정보주체는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. 회사는 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.<br>
                    ▶ 개인정보 열람청구 접수·처리 부서<br>
                    부서명 : 영업본부<br>
                    담당자 : 조장원 이사<br>
                    연락처 : ken25@f1security.co.kr<br><br>


                    제12조(권익침해 구제방법)<br>
                    정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다.<br>
                    아래의 기관은 회사와는 별개의 기관으로서, 회사의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다<br><br>

                    ▶ 개인정보 침해신고센터 (한국인터넷진흥원 운영)<br>
                        - 소관업무 : 개인정보 침해사실 신고, 상담 신청<br>
                        - 홈페이지 : privacy.kisa.or.kr<br>
                        - 전화 : (국번없이) 118<br>
                        - 주소 : (58324) 전남 나주시 진흥길 9(빛가람동 301-2) 3층 개인정보침해신고센터<br><br>

                    ▶ 개인정보 분쟁조정위원회<br>
                        - 소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)<br>
                        - 홈페이지 : www.kopico.go.kr<br>
                        - 전화 : (국번없이) 1833-6972<br>
                        - 주소 : (08513) 서울 금천구 벚꽃로 234 에이스하이엔드타워6차 1402호<br><br>

                    ▶ 대검찰청 사이버범죄수사단 : 1301 (www.spo.go.kr)<br>
                    ▶ 경찰청 사이버안전국 : 182 (http://cyberbureau.police.go.kr)<br><br>


                    제13조(개인정보 처리방침 변경) <br>
                    ① 이 개인정보 처리방침은 2022. 11. 01부터 적용됩니다.<br>
                    ② 이전의 개인정보 처리방침은 아래에서 확인하실 수 있습니다.<br> 
                    <a href="/file/PrivacyPolicy.hwp" download>- 2018. 12. 10 ~ 2022. 10. 31 적용 (클릭)</a>
                </p>
            </v-container>
        </section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
    },
};
</script>
