<template>
    <div class="sub-visual-wrap">
        <div class="sub-visual">
            <div class="sub-visual-tit">
                <v-container>
                    <h2>{{sh}}</h2>
                    <p>{{sc}}</p>
                </v-container>
            </div>
            <div class="sub-visual-bg" :style="'background-image:url('+ bg +');'">
                <v-container>
                    <div class="sub-visual-icon" data-aos="fade-left" v-if="icon" :style="'background-image:url('+ icon +');'"></div>
                </v-container>
            </div>
        </div>
        <v-container>
            <client-gnb className="sub-tab-wrap" :tabActive="tabActive" :sh="sh"></client-gnb>
        </v-container>
    </div>
</template>

<script>
import ClientGnb from "@/components/client/inc/client-gnb.vue";

export default{
    props : {
        sh : {type :String, default : ""},
        sc : {type :String, default : ""},
        bg : {type :String, default : ""},
        icon : {type :String, default : ""},
        tabActive : {type :String, default : ""},
    },
    components: {
        ClientGnb,
    },
    data: () => {
        return {
        }
    },
	created() {
	},
	mounted() {
		this.init();
	},
	methods : {
		init : function() {
            setTimeout(() => {
                AOS.init({
                    once: false,
                    offset: 240,
                    duration: 500,
                    throttleDelay : 100,
                    easing : 'ease-in-out',
                    anchorPlacement : 'top-center',
                });
            }, 100);
		}
	},
}
</script>

<style scoped>

</style>